
import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import Editable from '@/mixins/Editable';
import VWrapper from '@/components/shared/VWrapper.vue';
import { namespace } from 'vuex-class';
import Notification from '@/mixins/Notification';
import VenueLabelForm from '@/components/venueLabel/VenueLabelForm.vue';
import { Venue } from '@/interfaces/models/Venue';
import { VenueLabel } from '@/interfaces/models/VenueLabel';
import EditVenueLabelLoader from '@/components/venueLabel/EditVenueLabelLoader.vue';
import StackedForm from '@/mixins/StackedForm';

const app = namespace('app');
const venueLabel = namespace('venueLabel');

@Component({
  components: { EditVenueLabelLoader, VenueLabelForm, VWrapper },
})
export default class EditVenueLabel extends mixins(Editable, Notification, StackedForm) {
  @app.State('venues') public venues!: Venue[];

  @venueLabel.Action('store') public store!: any;
  @venueLabel.Action('show') public show!: any;
  @venueLabel.Action('update') public update!: any;
  @venueLabel.State('active') public active!: VenueLabel;

  public $refs!: {
    form: InstanceType<typeof VenueLabelForm> & { getData: () => any; validate: () => Promise<boolean | boolean[]> };
  };

  get title() {
    return this.editing ? 'venueLabel.edit' : 'venueLabel.create';
  }

  get venueLabel() {
    if (this.editing && this.active) {
      return this.active;
    }

    return null;
  }

  public async mounted() {
    if (this.editing) {
      this.$startLoading('venueLabel');
      await this.show({ id: this.id });
    }

    if (this.editing && !this.active) {
      this.$router.push({ name: 'venueLabel.index' });
    }
    this.$stopLoading('venueLabel');
  }

  public save() {
    this.$refs.form.validate().then(async (res: boolean | boolean[]) => {
      if (this.isValid(res)) {
        if (this.editing) {
          await this.update({ ...this.$refs.form.getData(), id: this.active!._id });
        } else {
          await this.store({ ...this.$refs.form.getData() });
        }
        this.$router.push({ name: 'venueLabel.index' });
      } else {
        this.notifyError('notification.form');
      }
    });
  }
}
