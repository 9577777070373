
import { Component, Prop } from 'vue-property-decorator';
import VFormBuilder from '../shared/form/VFormBuilder.vue';
import { InputType } from '@/enums/InputType';
import StackedForm from '../../mixins/StackedForm';
import { mixins } from 'vue-class-component';
import { Venue } from '@/interfaces/models/Venue';
import { venueFilter } from '@/util/helper';

@Component({
  components: { VFormBuilder },
})
export default class VenueLabelForm extends mixins(StackedForm) {
  @Prop({ type: Array, required: true }) public venueItems!: Venue[];

  public $refs!: {
    form: InstanceType<typeof VFormBuilder> & { form: any };
  };

  get items() {
    return [
      { name: 'name', type: InputType.Text, label: 'venueLabel.form.name', rules: 'required' },
      { name: 'slug', type: InputType.Text, label: 'venueLabel.form.slug', rules: 'required|venue_label' },
      {
        name: 'venues',
        type: InputType.Autocomplete,
        label: 'venueLabel.form.venues',
        multiple: true,
        items: this.venueItems,
        itemValue: '_id',
        itemText: 'name',
        filter: venueFilter,
      },
      {
        name: 'visibleTo',
        type: InputType.Autocomplete,
        label: 'venueLabel.form.visibleTo',
        multiple: true,
        items: this.venueItems,
        itemValue: '_id',
        itemText: 'name',
        filter: venueFilter,
      },
    ];
  }
}
